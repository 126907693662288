@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;200;300;400;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
  letter-spacing: 0.03em;
}

body {
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  background: #fff;
}

#root {
  min-height: 100vh;
}

.pagination {
  margin: 2rem 0 !important;
}

.page-item a.page-link {
  color: #1a1a1a !important;
}

.page-item.active .page-link {
  background: #1a1a1a !important;
  border: 1px solid #1a1a1a !important;
  color: #fff !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e3e5ee;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c3c1d0;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media print {
  html,
  body {
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
}
